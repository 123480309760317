<template>
  <div class="vm-modal click2pay">
    <h3 v-html="$t('paywall.form.click2pay.modal.title')"></h3>
    <div><img src="/web/img/icons/visa-mastercard-c2p.svg" alt="Click to Pay Logo"></div>
    <h4 v-html="$t('paywall.form.click2pay.modal.processError')"></h4>
  </div>
</template>

<script>
export default {
  name: 'Click2PayPrcessErrorModal'
}
</script>

<style lang="scss" scoped>
.vm-modal {
  h3 {
    color: #3C3C46;
    font-family: 'Lato Bold', Helvetica, Arial, sans-serif;
    border-bottom: 1px solid #3C3C46;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  h4 {
    font-family: 'Lato Bold', Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #3C3C46;
    margin-top: 10px;
  }

  p {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-top: 10px;
  }
}
</style>
